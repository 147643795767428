import { TIPO_SOLICITUD_ALIMENTOS, TIPO_SOLICITUD_FORMACION, TIPO_SOLICITUD_GESTION, TIPO_SOLICITUD_PRODUCTO } from "constants/constants";
import { SolicitudContext } from "context/solicitud/solicitudContext";
import NuevaCotizacionAlimentos from "pages/NuevaCotizacionAlimentos";
import NuevaCotizacionGestion from "pages/NuevaCotizacionGestion";
import NuevaCotizacionProducto from "pages/NuevaCotizacionProducto";
import NuevaCotizacionFormacion from "pages/formacion/NuevaCotizacionFormacion";
import { useContext } from "react";



function NuevaCotizacionBasePage(props) {

    const { stateSolicitud } = useContext(SolicitudContext);
    const tipoSolicitud = stateSolicitud.tipo;

    if(tipoSolicitud === TIPO_SOLICITUD_GESTION){
        return <NuevaCotizacionGestion {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_ALIMENTOS){
        return <NuevaCotizacionAlimentos {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_PRODUCTO){
        return <NuevaCotizacionProducto {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_FORMACION){
        return <NuevaCotizacionFormacion {...props} />;
    }

    return <NuevaCotizacionGestion {...props} />;
}

export default NuevaCotizacionBasePage;