import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import { Typography } from '@mui/material';
import { useContext } from 'react';
import { SolicitudContext } from 'context/solicitud/solicitudContext';
import { searchOrganizaciones } from 'services/Organizacion/organizacionService';


export default function InputOrganizaciones({ handleOrganizacion, required, onOrganizacionesChange }) {
    const [value, setValue] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);

    const {stateSolicitud} = useContext(SolicitudContext)
    const {organizaciones} = stateSolicitud

    React.useEffect(() => {
        setValue(organizaciones.map(org => {
            if(org.label){
                return org;
            }else{
                return (
                    {
                        id: org.id,
                        label: org.razonSocialAFIP,
                        nombre: org.razonSocialAFIP,
                        cuit: org.cuit
                    }
                );
            }
        }))
    }, [organizaciones])

    const searchEmpresa = async (request, callback) => {

        try {
            let organizaciones = await searchOrganizaciones(request.input);
            organizaciones = organizaciones
                .map(org => (
                    {
                        id: org.id,
                        label: org.razonSocialAFIP,
                        nombre: org.razonSocialAFIP,
                        cuit: org.cuit
                    }
                ))
            return callback(organizaciones);
        } catch (error) {
            callback([]);
        }

    }

    const fetch = React.useMemo(() => debounce(searchEmpresa,400),[]);

    React.useEffect(() => {
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            let newOptions = [];

            if (value) {
                newOptions = [value];
            }

            if (results) {
                newOptions = [...newOptions, ...results];
            }
            setOptions(newOptions);
        });
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            id="search-orgs"
            
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.label
            }
            options={options.filter(option => !value.some(v => v.id === option.id))}
            autoComplete
            includeInputInList
            multiple
            fullWidth
            value={value}
            noOptionsText="No se encontraron resultados"
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                onOrganizacionesChange(newValue);
                handleOrganizacion(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField required={required ? value == null || value == undefined || value.length == 0 : false}  {...params} label="Organizaciones" fullWidth />
            )}
            renderOption={(props, option) => {

                return (
                    <li {...props}>
                        <Typography variant="body2">
                            {option.label}
                        </Typography>
                    </li>
                );
            }}
        />

    );
}