import ModalIntegracion from 'components/modalIntegracion'
import PaperDatosCotizacion from 'components/paperDatosCotizacion'
import PaperNorma from 'components/paperInputNormas'
import { RUTAS } from 'constants/routes'
import { setDatosCotizacion, setEstadoCotizacion, setJson, setNormaMotivo, updateCotizacion } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import { SolicitudContext } from 'context/solicitud/solicitudContext'
import React, {  useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setError } from 'redux/slices/globalSlice'
import { postCotizaciones } from 'services/Cotizacion'
import { parseNormas } from 'utlis/normasParser'

function FormCotizacion() {

  const history = useHistory();
  //global context
  const dispatchRedux = useDispatch()

  //contexto solicitud
  const {stateSolicitud} = useContext(SolicitudContext)
  
  //contexto cotizacion
  const {state, dispatch} = useContext(CotizacionContext);
  const {idSolicitud} = stateSolicitud
  const {inputNormasMotivos, json, multisitios, inputIntegracion, inputDatosCotizacion, inputCodigosIAFNACE} = state;

  const [modoEdicion, setModoEdicion] = useState(false)

  //Modal
  const [modalState, setModalState] = useState({opened: false});
  const handleModal = () => setModalState({opened: !modalState.opened})

  const navigateToCotizacion = (idCotizacion) => {
    history.push(
        RUTAS.COTIZACION
        .replace(":idSolicitud", idSolicitud)
        .replace(":idCotizacion", idCotizacion));
  }

  const handleGuardarCotizacion = (e) => {
    e.preventDefault()
      const listaNormas = inputNormasMotivos.map(
        function(norma){

            let ente = null
            let fechaVencimientoAux = null
            let clusterAux = null
            let numeroMantenimientoAux = null
            if(norma.motivo === "RECERTIFICACION"){
                fechaVencimientoAux = norma.vencimiento
            }

            if(norma.transferencia){
                ente = {id: norma.ente}
            }
            if(norma.cluster){
                clusterAux = norma.cluster
            }
            if(norma.numero !== ""){
                numeroMantenimientoAux = norma.numero 
            }

            return {
                id:norma.id,
                integrada: norma.integrada,
                norma:{
                    id: norma.norma,
                    nombre:''
                },
                motivo: norma.motivo,
                numeroMantenimiento:numeroMantenimientoAux,
                esReduccionDeAlcance: norma.reduccion,
                esAmpliacionDeAlcance: norma.ampliacion,
                esCambioDeVersion: norma.cambioVersion,
                esTransferencia: norma.transferencia,
                fechaVencimientoCertificado: fechaVencimientoAux,
                ente:ente,
                cluster:clusterAux,
                consumoEnergiaAnual: norma.consumoEnergiaAnual,
                fuentesDeEnergia: norma.fuentesDeEnergia,
                usosDeEnergia: norma.usosDeEnergia
            }
        }
    )
    let infoIntegracionAux = null;
    if(inputIntegracion.motivosOrganizacion.length > 0){
        infoIntegracionAux = {...inputIntegracion, id:0}
    }
    const data = {
        idSolicitud: idSolicitud,
        nombre: inputDatosCotizacion.nombre,
        codigo: inputDatosCotizacion.codigo,
        cantidadMantenimientos: inputDatosCotizacion.seguimientos,
        NACEs: inputCodigosIAFNACE.map(item => ({id: item.CodigoNace.id})),
        normas: listaNormas,
        infoIntegracion:infoIntegracionAux,
        usaMultisitios: multisitios
    }
    
     if(json.id === "" || json.id === null || json.id === undefined)
     {
        postCotizaciones(data)
        .then(response => {
            let estadoAux = {...response.estado}
            estadoAux.posiblesTransiciones = estadoAux.posiblesTransiciones.concat(estadoAux.estado)
            dispatch(setEstadoCotizacion(estadoAux))
            const normasMotivos = parseNormas(response.normas)
            dispatch(setNormaMotivo(normasMotivos))

            const info = {
                nombre:response.nombre,
                seguimientos:response.cantidadMantenimientos, 
            codigo:response.codigo,
            }

            dispatch(setDatosCotizacion(info))

            dispatch(setJson(response))
        
            navigateToCotizacion(response.id);
            setModoEdicion(false)
        })
        .catch(error => {
            dispatchRedux(setError({estado:true, tipo:error.status, descripcion:error.data.details?error.data.details:"sin detalles"}))
            console.error("ERROR POST COTIZACION:", error)
            console.error("body",data)
        })
        }
    else{
        updateCotizacion(dispatch, {...data, id:json.id});
        setModoEdicion(false)
    }
  }

return (
<>
    <form onSubmit={handleGuardarCotizacion}>
        <PaperDatosCotizacion />
        <PaperNorma 
          handleModal={handleModal}
          modoEdicion={modoEdicion}
          setModoEdicion={setModoEdicion}
          />
    </form>
       <ModalIntegracion
       onClose={()=>handleModal()} 
       isOpened={modalState.opened}
       integracion={inputIntegracion} 
       sitio={null}
       isSitio={false}
       isGeneral={true}
       />
</>
  )
}

export default FormCotizacion