import { TIPO_SOLICITUD_ALIMENTOS, TIPO_SOLICITUD_FORMACION, TIPO_SOLICITUD_GESTION, TIPO_SOLICITUD_PRODUCTO } from "constants/constants";
import { SolicitudContext } from "context/solicitud/solicitudContext";
import CotizacionAlimentos from "pages/CotizacionAlimentos";
import CotizacionFormacion from "pages/CotizacionFormacion";
import CotizacionGestion from "pages/CotizacionGestion";
import CotizacionProducto from "pages/CotizacionProducto";
import { useContext } from "react";



function CotizacionBasePage(props) {

    const { stateSolicitud } = useContext(SolicitudContext);
    const tipoSolicitud = stateSolicitud.tipo; 

    if(tipoSolicitud === TIPO_SOLICITUD_GESTION){
        return <CotizacionGestion {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_ALIMENTOS){
        return <CotizacionAlimentos {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_PRODUCTO ){
        return <CotizacionProducto {...props} />;
    }
    
    if(tipoSolicitud === TIPO_SOLICITUD_FORMACION){
        return <CotizacionFormacion {...props} />;
    }

    return <p>cargando</p>;
}

export default CotizacionBasePage;