import React, { useContext } from 'react';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';


const SelectProductos = ({onChange}) => {

   const { cursos, loading } = useSelector(state => state.cursos)
   
   const { state } = useContext(CotizacionContext)

   const onSelectProducto = ( _ , productoAutoComplete) => {
      const producto = getOptions().find(p => p.id ===  productoAutoComplete.id);
      onChange(producto);
   }

   const getOptions = () => {
      const cursosOptions = cursos.map(c => ({id: c.id, label: c.nombre, tipo: 'curso'}))
      const productosOptions = state.productos.map(p => ({id: p.id, label: p.nombre, tipo: 'producto'}))

      return [...cursosOptions]
   }

   return (
      <Autocomplete
      disablePortal
      onChange={onSelectProducto}
      id="combo-box-demo"
      options={getOptions()}
      renderInput={(params) => <TextField {...params} label="Concepto" />}
      />
   );
};

export default SelectProductos;
