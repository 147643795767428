import { useContext, useState } from "react";
import LabelCustom from "components/labelCustom";
import { SolicitudContext } from "context/solicitud/solicitudContext";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Button, CircularProgress, Fab, Grid, Paper, TextField, Typography } from "@mui/material";
import { CotizacionContext } from "context/cotizacion/cotizacionContext";
import { postDuplicarSolicitud } from "services/Solicitud";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from 'react-toastify';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { ModalResumenSolicitud } from "components/ModalResumenSolicitud/ModalResumenSolicitud";
import { useDispatch } from "react-redux";
import { setError } from "redux/slices/globalSlice";

export default function DetailSolicitud() {
    const history = useHistory();
    const dispatchRedux = useDispatch();
    const { stateSolicitud } = useContext(SolicitudContext);
    const { organizaciones, personalEfectivo, estado, numero, anio, elaborado } = stateSolicitud;

    const {state: stateCotizacion} = useContext(CotizacionContext);
    const {estadoCotizacion, json} = stateCotizacion;
    const [toggleDuplicarSolicitud, setDuplicarSolicitud] = useState(false);
    const modoEdicion = false;

    const [showResumenSolicitud, setShowResumenSolicitud] = useState(false);

    const handleEdicion = () => {
    }

    const handlePersonalEfectivo = (e) => {
    }

    const handleDuplicarSolicitud = async (e) =>  {
        try 
        {
            setDuplicarSolicitud(true);
            let solicitudDuplicada = await postDuplicarSolicitud(stateSolicitud.idSolicitud);
            toast("Solicitud duplicada con éxito");
            history.push(`/solicitud/${solicitudDuplicada.id}`);
            setDuplicarSolicitud(false);
        }
        catch (error) {
            dispatchRedux(setError({estado:true, tipo:error?.status, descripcion:error?.data?.details?error.data.details:"sin detalles"}));
            console.error("ERROR POST DUPLICAR SOLICITUD, ERROR: "+error);
            setDuplicarSolicitud(false);
        }
    }

    return (
        <>
            <Paper className="my_paper" elevation={5} sx={{ position: "relative" }}>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start">
                    <Grid item xs={3} p={1}>
                        <Typography variant="h6">Solicitud de Cotización</Typography>
                    </Grid>
                    <Grid item xs={2}><LabelCustom label={"Número"} value={numero}></LabelCustom></Grid>
                    <Grid item xs={2}><LabelCustom label={"Año"} value={anio}></LabelCustom></Grid>
                    <Grid item xs={2}><LabelCustom label={"Elaborado"} value={elaborado}></LabelCustom></Grid>
                    <Grid item xs={3}>
                        <Button disabled variant="outlined" startIcon={<FiberManualRecordIcon />}>
                            {estado}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container
                    direction={"row"}>
                    <Grid item xs={5}>
                        <Typography variant='body2'>Organizaciones: {organizaciones.map(o => o.nombre).join(', ')}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {
                            personalEfectivo > 0 ? 
                            modoEdicion ?
                                <TextField label="Personal Efectivo" value={personalAux} onChange={handlePersonalEfectivo}></TextField>
                                :
                                <Typography variant='body2'>Personal Efectivo: {personalEfectivo}</Typography> 
                                :
                                null
                        }

                    </Grid>
                    <Grid item xs={4} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                            <Button variant="contained"
                            color='primary'
                            startIcon={toggleDuplicarSolicitud ? <CircularProgress style={{'color': 'white'}} /> : null}
                             onClick={handleDuplicarSolicitud}
                            >
                            Duplicar solicitud   
                            </Button>
                            <Button
                                onClick={() => setShowResumenSolicitud(true)}
                                 variant="outlined"
                                 color="secondary"
                                 startIcon={<SummarizeIcon/>}
                                 >
                                    RESUMEN
                                 </Button>
                        </Grid>
                </Grid>

                <ModalResumenSolicitud isOpened={showResumenSolicitud} onClose={() => setShowResumenSolicitud(false)}/>

                {/* {
                    (estadoCotizacion.permiteEdicion || estadoCotizacion.estado === TIPO_ESTADO.ENVIADA) && json.id !== '' &&
                    // <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={{ position: "absolute", right: "-70px", top: "60%" }}
                        onClick={handleEdicion}
                        disabled={estadoCotizacion.guardando}
                    >
                        {
                            modoEdicion ?
                                <SaveIcon /> : <EditIcon />
                        }
                    </Fab>
                    // </Restricted>
                } */}
            </Paper>
        </>
    );

}